<template>
  <div>
    <div v-if="!isMobile" class="pc">
      <template-home-pc></template-home-pc>
    </div>
    <div v-else class="mobile">
      <template-home-mobile></template-home-mobile>
    </div>
  </div>
</template>

<script>
import TemplateHomePc from './TemplateHomePc';
import TemplateHomeMobile from './TemplateHomeMobile';
export default {
  name: 'Home',
  components: { TemplateHomeMobile, TemplateHomePc },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="stylus" scoped></style>
